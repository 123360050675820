<template>
    <div id="faqCard">
        <b-card no-body>
            <b-card-header>
                <h4 class="card-title">
                    <slot name="question" />
                </h4>
            </b-card-header>
            <b-card-body>
                <p class="card-text">
                    <slot name="answer" />
                </p>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
    export default {
        name: "faqCard"
    }
</script>