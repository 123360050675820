<template>
    <div class="foot">
        <div class="container footer">
            <div class="row">
                <div class="col-md-10 offset-md-1">
                    <div class="text-center mb-1">
                        <a class="mr-2" href="mailto:info@flightclub.dance">
                            <b-img :src="require('../../assets/icons/png/email.png')" alt="Email symbol"/>
                        </a>
                        <a href="https://www.facebook.com/flightclub.dance">
                            <b-img :src="require('../../assets/icons/png/facebook.png')" alt="Facebook symbol"/>
                        </a>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-10 offset-md-1">
                    <p>
                        Under no circumstances should you attempt to reproduce any of the moves contained on this site
                        without qualified instruction.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "foot"
    }
</script>

<style scoped>

</style>