<template>
  <div id="logoutBody">
    <card>
      <template v-slot:body>
        <p>You should be shortly redirected to the home page. If not please navigate from the main menu.</p>
      </template>
    </card>
  </div>
</template>

<script>
import card from '../shared/Card'
import {userBus} from '../../scripts/user.js'
import {router} from '../../main.js'

export default {
  name: "logoutBody",
  components: {
    card
  },
  mounted() {
    userBus.clearTokens()
    router.push("/")
  }
}
</script>
