<template>
  <div id="about">
    <page>
      <template v-slot:header>
        <about-us-header/>
      </template>
      <template v-slot:body>
        <about-us-body/>
      </template>
    </page>
  </div>
</template>

<script>

  import Page from "../shared/Page";
  import AboutUsHeader from "./Header";
  import AboutUsBody from "./Body";

  export default {
    name: 'aboutHome',
    components: {
      AboutUsBody,
      AboutUsHeader,
      Page
    }
  }
</script>
