<template>
    <div id="galleryHeader">
        <h1><strong>Gallery</strong></h1>
    </div>
</template>

<script>
    export default {
        name: "galleryHeader"
    }
</script>