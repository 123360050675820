<template>
  <div id="acroHub">
    <server-connected-page>
      <template v-slot:header>
        <acro-hub-header/>
      </template>
      <template v-slot:body>
        <acro-hub-body/>
      </template>
    </server-connected-page>
  </div>
</template>

<script>
import {userBus} from "@/scripts/user";
import {router} from "@/main";
import {acroHubBus} from "@/scripts/acroHub";
import AcroHubHeader from "./Header";
import AcroHubBody from "./Body";
import ServerConnectedPage from "../shared/ServerConnectedPage";

export default {
  name: 'acroHub',
  components: {
    ServerConnectedPage,
    AcroHubBody,
    AcroHubHeader
  },
  mounted() {
    if (userBus.profile) {
      if (!acroHubBus.moves || acroHubBus.moves.length === 0) acroHubBus.getMoves(userBus.accessToken, userBus.idToken)
    } else {
      router.push('/')
    }
  }
}
</script>
