<template>
  <div id="galleryBody">
    <activeGallery :gallery="activeAlbum"/>
    <galleryFilter :filter="filter"/>
    <galleryListing :albums="albums" @updateActive="setActiveAlbum($event)"/>
  </div>
</template>

<script>
import {galleryBus} from './galleries'
import activeGallery from './ActiveGallery'
import galleryFilter from './GalleryFilter'
import galleryListing from './GalleryListing'

export default {
  name: 'galleryBody',
  components: {
    activeGallery,
    galleryFilter,
    galleryListing
  },
  data() {
    return {
      allAlbums: galleryBus.getAll(),
      filter: {
        type: "All",
        year: "All",
        text: undefined
      },
      activeAlbum: galleryBus.getActive()
    }
  },
  computed: {
    albums: function () {
      return this.allAlbums
          .filter(this.filterType)
          .filter(this.filterDate)
          .filter(this.filterText)
          .sort(this.sortByDate);
    }
  },
  methods: {
    filterType: function (album) {
      return this.filter.type === 'All'
          || (this.filter.type === 'Pictures' && album.type === 'photo')
          || (this.filter.type === 'Videos' && album.type === 'video')
    },
    filterDate: function (album) {
      if (this.filter.year === 'All') return true;
      const albumDate = Date.parse(album.date);
      const startDate = Date.parse(this.filter.year + '-01-01');
      const endDate = Date.parse(this.filter.year + '-12-31');
      return startDate <= albumDate && albumDate <= endDate;
    },
    filterText: function (album) {
      return !this.filter.text
          || album.title.toLowerCase()
              .includes(this.filter.text.toLowerCase())
          || album.description.toLowerCase()
              .includes(this.filter.text.toLowerCase());
    },
    sortByDate: function (a, b) {
      const dateA = Date.parse(a.date);
      const dateB = Date.parse(b.date);
      return dateA < dateB ? 1 : -1;
    },
    setActiveAlbum: function (album) {
      this.activeAlbum = album;
      window.scrollTo(0, 0);
    }
  }
}
</script>
