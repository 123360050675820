<template>
  <div id="aboutUsBody">
    <card>
      <template v-slot:body>
        <intro/>
      </template>
    </card>
    <card-set :cards=whatWeDo />
  </div>
</template>

<script>
import card from '../shared/Card'
import cardSet from '../shared/CardImageSet'
import intro from './Intro'
import {whatWeDo} from './whatWeDo.js'

export default {
  name: "aboutUsBody",
  data() {
    return {
      whatWeDo: whatWeDo
    }
  },
  components: {
    card,
    cardSet,
    intro
  }
}
</script>
