<template>
  <div id="classRecordBody">
    <b-container fluid class="mb-3">
      <b-row class="justify-content-center">
        <b-col cols="12">
          <card>
            <template v-slot:body>
              <b-form>
                <b-form-group
                    label-cols-lg="2"
                    label="Search"
                    label-for="text-selector">
                  <b-form-input id="text-selector" v-model="textFilter" type="text"/>
                </b-form-group>
                <b-form-group
                    label-cols-lg="2"
                    label="Show difficulty"
                    label-for="difficulty-selector">
                  <b-form-checkbox id="difficulty-selector" v-model="showDifficulty"/>
                </b-form-group>
              </b-form>
            </template>
          </card>
        </b-col>
      </b-row>
      <b-row class="justify-content-center">
        <b-col cols="12">
          <card>
            <template v-slot:body>
              <b-table
                  hover
                  responsive
                  :items="items"
                  :fields="fields"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc">
                <template #cell(name)="data">
                  <router-link v-if="data.item.link != null" :to="data.item.link">{{ data.item.name }}</router-link>
                  <div v-else>{{ data.item.name }}</div>
                </template>
              </b-table>
            </template>
          </card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {acroHubBus} from "@/scripts/acroHub";
import card from "@/components/shared/Card";

export default {
  name: 'classRecordBody',
  data() {
    return {
      sortBy: "date",
      sortDesc: true,
      textFilter: '',
      showDifficulty: false
    }
  },
  components: {
    card
  },
  methods: {
    mapAcroMove(key) {
      return this.mapMove(key, acroHubBus.moves[key], "acrobatics")
    },
    mapMove(key, move, suffix) {
      return {
        name: move["name"],
        difficulty: move["difficulty"] !== undefined ? move["difficulty"] : null,
        date: move["datesTaught"] != null && move["datesTaught"].length > 0 ? move["datesTaught"][0] : null,
        link: (move["videos"] !== undefined && move["videos"]["demo"] !== undefined) ? "/trainingHub/" + suffix + "/" + key : null
      }
    },
    filterMoves(move) {
      return !this.textFilter || move.name.toLowerCase().includes(this.textFilter.toLowerCase())
    },
    acroItems() {
      return Object.keys(acroHubBus.moves)
          .map(this.mapAcroMove)
          .filter(this.filterMoves)
    },
  },
  computed: {
    items() {
      return this.acroItems()
    },
    fields() {
      let fields = [
        {key: "name", label: "Name", sortable: true},
        {key: "date", label: "Last taught", sortable: true}
      ]
      if (this.showDifficulty) {
        fields.push({key: "difficulty", label: "Difficulty", sortable: true})
      }
      return fields
    }
  }
}
</script>
