<template>
    <div id="acroHubHeader">
        <h1><strong>Acrobatics training hub</strong></h1>
    </div>
</template>

<script>
    export default {
        name: "acroHubHeader"
    }
</script>
