<template>
  <div id="registrationSuccessful">
    <page>
      <template v-slot:header>
        <registration-successful-header/>
      </template>
      <template v-slot:body>
        <registration-successful-body/>
      </template>
    </page>
  </div>
</template>

<script>

  import Page from "../shared/Page";
  import RegistrationSuccessfulHeader from "./Header";
  import RegistrationSuccessfulBody from "./Body";

  export default {
    name: 'registrationSuccessful',
    components: {
      RegistrationSuccessfulBody,
      RegistrationSuccessfulHeader,
      Page
    }
  }
</script>
