<template>
  <div id="classRecord">
    <server-connected-page>
      <template v-slot:header>
        <class-record-header/>
      </template>
      <template v-slot:body>
        <class-record-body/>
      </template>
    </server-connected-page>
  </div>
</template>

<script>
import {userBus} from "@/scripts/user";
import {router} from "@/main";
import {acroHubBus} from "@/scripts/acroHub";
import ServerConnectedPage from "../shared/ServerConnectedPage";
import ClassRecordHeader from "./Header";
import ClassRecordBody from "./Body";

export default {
  name: 'acroHub',
  components: {
    ClassRecordBody,
    ClassRecordHeader,
    ServerConnectedPage,
  },
  mounted() {
    if (userBus.profile) {
      if (!acroHubBus.moves || acroHubBus.moves.length === 0) acroHubBus.getMoves(userBus.accessToken, userBus.idToken)
    } else {
      router.push('/')
    }
  }
}
</script>
