import Vue from 'vue'
import jwtDecode from 'jwt-decode'
import {eventBus} from "./events";
import {requestBus} from "./request"
import {urlBuilder} from "./urlBuilder";

const refreshTokenKey = "refreshToken"
const idTokenKey = "idToken"
const accessTokenKey = "accessToken"

export const userBus = new Vue({
  data: function () {
    return {
      accessToken: null,
      refreshToken: null,
      idToken: null,
      registrationError: null,
      loginError: null,
      resetPasswordRequestError: null,
      resetPasswordConfirmationError: null
    }
  },
  created() {
    this.setTokens(this.getLocalTokens())
  },
  methods: {
    getTokens(username, password, remember, recaptchaToken) {
      const payload = {username, password, remember, recaptchaToken};
      requestBus.request(
        urlBuilder.build("user/tokens"),
        "POST",
        payload,
        {
          onSuccess: this.loggedIn, onFailure: function (error) {
            // eslint-disable-next-line no-console
            console.warn("Login error: " + JSON.stringify(error))
            userBus.loginError = error
          }
        }
      )
    },
    refreshTokens(username, refreshToken) {
      const payload = {username, refreshToken};
      requestBus.request(
        urlBuilder.build("user/tokens/renew"),
        "POST",
        payload,
        {
          onSuccess: this.loggedIn, onFailure: function (error) {
            // eslint-disable-next-line no-console
            console.warn("Refresh token error: " + JSON.stringify(error))
          }
        }
      )
    },
    loggedIn(tokens) {
      this.setTokens(tokens)
      this.loginError = null
      eventBus.loggedIn()
    },
    registered() {
      this.registrationError = null
      eventBus.registered()
    },
    passwordResetRequested() {
      this.resetPasswordRequestError = null
      eventBus.passwordResetRequested()
    },
    passwordResetConfirmed() {
      this.resetPasswordConfirmationError = null
      eventBus.passwordResetConfirmed()
    },
    setTokens(newTokens) {
      this.setLocalTokens(newTokens)
      this.accessToken = this.safeGetProperty(newTokens, accessTokenKey, this.accessToken)
      this.idToken = this.safeGetProperty(newTokens, idTokenKey, this.idToken)
      this.refreshToken = this.safeGetProperty(newTokens, refreshTokenKey, this.refreshToken)
    },
    safeGetProperty(data, property, defaultValue) {
      return (data && data.hasOwnProperty(property)) ? data[property] : defaultValue
    },
    clearTokens() {
      this.setTokens({
        [accessTokenKey]: null,
        [idTokenKey]: null,
        [refreshTokenKey]: null
      })
    },
    getLocalTokens() {
      return {
        [accessTokenKey]: this.convertNull(localStorage.flightClubAccessToken),
        [idTokenKey]: this.convertNull(localStorage.flightClubIdToken),
        [refreshTokenKey]: this.convertNull(localStorage.flightClubRefreshToken)
      }
    },
    setLocalTokens(newTokens) {
      localStorage.flightClubAccessToken = this.safeGetProperty(newTokens, accessTokenKey, null)
      localStorage.flightClubIdToken = this.safeGetProperty(newTokens, idTokenKey, null)
      localStorage.flightClubRefreshToken = this.safeGetProperty(newTokens, refreshTokenKey, null)
    },
    convertNull(stringValue) {
      return stringValue === "null" ? null : stringValue
    },
    tokenExpired(token) {
      // eslint-disable-next-line no-console
      console.log("Token expiry: " + token["exp"])
      return token["exp"] * 1000 < new Date().getTime()
    },
    register(username, email, password, recaptchaToken) {
      const payload = {
        username,
        email,
        password,
        recaptchaToken
      }
      requestBus.request(
        urlBuilder.build("user/register"),
        "POST",
        payload,
        {
          onSuccess: this.registered, onFailure: function (error) {
            // eslint-disable-next-line no-console
            console.warn("Registration error: " + JSON.stringify(error))
            userBus.registrationError = error
          }
        }
      )
    },
    requestResetPassword(username, recaptchaToken) {
      const payload = {
        username,
        recaptchaToken
      }
      requestBus.request(
        urlBuilder.build("user/resetPassword/request"),
        "POST",
        payload,
        {
          onSuccess: this.passwordResetRequested, onFailure: function (error) {
            // eslint-disable-next-line no-console
            console.warn("Reset password error: " + JSON.stringify(error))
            userBus.resetPasswordRequestError = error
          }
        }
      )
    },
    confirmResetPassword(username, password, verificationCode, recaptchaToken) {
      const payload = {
        username,
        password,
        verificationCode,
        recaptchaToken
      }
      requestBus.request(
        urlBuilder.build("user/resetPassword/confirm"),
        "POST",
        payload,
        {
          onSuccess: this.passwordResetConfirmed, onFailure: function (error) {
            // eslint-disable-next-line no-console
            console.warn("Reset password confirmation error: " + JSON.stringify(error))
            userBus.resetPasswordConfirmationError = error
          }
        }
      )
    }
  },
  computed: {
    profile: function () {
      if (this.idToken) {
        try {
          const decodedId = jwtDecode(this.idToken);
          const username = decodedId['cognito:username'];
          if (this.tokenExpired(decodedId)) {
            // eslint-disable-next-line no-console
            console.warn("Current ID token has expired")
            if (this.refreshToken) {
              this.refreshTokens(username, this.refreshToken);
            }
            return null
          } else {
            const groups = decodedId['cognito:groups'];
            return {
              username: username,
              email: decodedId['email'],
              groups: groups ? groups : [],
            }
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error("Unexpected localError interpreting id token: " + this.idToken)
          return null
        }
      } else {
        return null
      }
    }
  }
});
