export const whatWeDo = [
    {
        block: "We run two weekly sessions: a Wednesday session focused on teaching new moves and expanding skills, and a Sunday session focused on building confidence and team performances",
        title: "Classes",
        image: "class.jpg",
        imageAlt: "Dancers at one of Flight Club's classes doing a basic shoulder sit",
        icon: "gym",
        iconAlt: "Icon of person doing overhead stretch",
        link: null
    },    {
        block: "We have a lot of fun together, and love an opportunity to meet up outside of classes",
        title: "Socials",
        image: "social.jpg",
        imageAlt: "Team at ice skating social",
        icon: "party",
        iconAlt: "Icon of party funnel and confetti",
        link: null
    },
    {
        block: "Acrobatics looks cool. You can't deny it. Especially during lockdown, we did some really fun video projects to keep us connected and we've always got things in the works",
        title: "Media",
        image: "blenheim_palace_back_angel.jpg",
        imageAlt: "Back angel at Blenheim Palace",
        icon: "pictures",
        iconAlt: "Pictures icon",
        link: null
    },
    {
        block: "We love to showcase our routines. Why not have a look at some of our previous work in the gallery?",
        title: "Shows",
        image: "ddmn_jive_plus_christmas_2019.jpg",
        imageAlt: "The team from the Don't Drop Me Now performance at the Jive Plus Christmas Party 2019",
        icon: "show",
        iconAlt: "Theatre icon",
        link: null
    },
    {
        block: "We're always ready to put our skills to the test, and it's so much fun going to competitions as a team. We've brought home more than a few trophies over the years.",
        title: "Competitions",
        image: "jive_champs_2019_competitors.jpg",
        imageAlt: "Flight Club dancers hug at Jive Champs 2019",
        icon: "trophy",
        iconAlt: "Trophy icon",
        link: null
    },
];
