<template>
    <div id="classRecordHeader">
        <h1><strong>Class record</strong></h1>
    </div>
</template>

<script>
    export default {
        name: "classRecordHeader"
    }
</script>
