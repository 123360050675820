<template>
  <b-card class="cardWithImage" img-top :img-src="require('../../assets/images/' + image)" :img-alt=imageAlt>
    <b-card-body>
      <b-card-text class="text-center mb-1">
        <b-img :src="require('../../assets/icons/png/' + icon + '.png')" :alt=iconAlt />
        <h3 v-if="link" class="card-title text-center">
          <router-link :to="link">
            {{ title }}
          </router-link>
        </h3>
        <h3 v-if="!link" class="card-title text-center">
          {{ title }}
        </h3>
        <p class="card-text text-center">
          {{ block }}
        </p>
      </b-card-text>
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  name: 'cardWithImage',
  props: {
    block: String,
    title: String,
    icon: String,
    iconAlt: String,
    link: String,
    image: String,
    imageAlt: String,
  }
}
</script>
