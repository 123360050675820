<template>
    <div class="cardBody">
        <b-container fluid>
            <b-col class="col-10 offset-1">
                <b-card class="card" :bg-variant="bgVariant" :text-variant="textVariant" no-body>
                    <b-card-header v-show=this.$slots.header>
                      <slot name="header"></slot>
                    </b-card-header>
                    <b-card-body>
                      <slot name="body"></slot>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-container>
    </div>
</template>

<script>
    export default {
        name: 'card',
        props: {
            bgVariant: String,
            textVariant: String
        }
    }
</script>
