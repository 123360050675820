<template>
  <div id="acroHubMoveHeader">
    <h1><strong>{{ title }}</strong></h1>
  </div>
</template>

<script>
  export default {
    name: "acroHubMoveHeader",
    props: {
      title: String
    }
  }
</script>
