<template>
  <div id="intro">
    <p class="card-text text-justify">
      Flight Club began on 15th July 2018 with the goal of teaching acrobatic partner dance, and
      sharing that love of dancing and spectacle with as wide an audience as possible.
      Since then, our dancers
      have won multiple national titles both as couples and as part of team cabarets, We've performed at charity
      events and festivals, and every week we arrive eager to practice and learn new moves.
      The team is run as a
      not-for-profit by our excellent (and generous) team of volunteers and coaches.
      Below is a taste of the sort of things we do.
    </p>
  </div>
</template>

<script>
export default {
  name: "intro"
}
</script>
