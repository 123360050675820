<template>
    <div id="registrationSuccessfulHeader">
        <h1><strong>Registration Successful</strong></h1>
    </div>
</template>

<script>
    export default {
        name: "registrationSuccessfulHeader"
    }
</script>
