<template>
  <div id="resetPassword">
    <page>
      <template v-slot:header>
        <reset-password-header/>
      </template>
      <template v-slot:body>
        <reset-password-body/>
      </template>
    </page>
  </div>
</template>

<script>
  import Page from '../shared/Page.vue'
  import ResetPasswordHeader from "./Header";
  import ResetPasswordBody from "./Body";

  export default {
    name: 'home',
    components: {
      ResetPasswordBody,
      ResetPasswordHeader,
      Page
    }
  }
</script>
