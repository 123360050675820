<template>
    <div id="joinHeader">
        <h1><strong>Getting involved</strong></h1>
    </div>
</template>

<script>
    export default {
        name: "joinHeader"
    }
</script>