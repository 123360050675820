<template>
  <div id="home">
    <page>
      <template v-slot:header>
        <home-header/>
      </template>
      <template v-slot:subheader>
        <home-subheader/>
      </template>
      <template v-slot:body>
        <home-body/>
      </template>
    </page>
  </div>
</template>

<script>

  import Page from "../shared/Page";
  import HomeHeader from "./Header";
  import HomeBody from "./Body";
  import HomeSubheader from "./Subheader";

  export default {
    name: 'home',
    components: {
      HomeSubheader,
      HomeBody,
      HomeHeader,
      Page
    }
  }
</script>
