<template>
  <div id="resetPasswordForm">
    <b-container>
      <b-row>
        <b-col>
          <card v-show="error" bg-variant="danger" text-variant="white">
            <template v-slot:body>
              <h3>{{ error }}</h3>
            </template>
          </card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <card>
            <template v-slot:body>
              <form @submit.prevent="requestResetPassword()">
                <b-form-group>
                  <b-input-group prepend="Username">
                    <b-input v-model="username" required type="text">{{ username }}</b-input>
                  </b-input-group>
                </b-form-group>
                <b-form-group>
                  <recaptcha @validate="validateReCaptcha"/>
                </b-form-group>
                <div>
                  <b-button :disabled="!token" type="submit" variant="primary">Submit</b-button>
                </div>
              </form>
            </template>
          </card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import card from '../shared/Card'
import {userBus} from "@/scripts/user";
import recaptcha from '../shared/Recaptcha'

export default {
  name: "resetPasswordForm",
  data: function () {
    return {
      username: "",
      token: undefined,
      localError: undefined
    }
  },
  components: {
    card,
    recaptcha
  },
  methods: {
    validateReCaptcha(response) {
      this.token = response
    },
    async requestResetPassword() {
      userBus.requestResetPassword(this.username, this.token)
    }
  },
  computed: {
    error() {
      return userBus.resetPasswordRequestError ? userBus.resetPasswordRequestError.data.message : null;
    }
  }

}
</script>
