<template>
    <div id="signInHeader">
        <h1><strong>Sign in</strong></h1>
    </div>
</template>

<script>
    export default {
        name: "signInHeader"
    }
</script>