<cardColumns>
</cardColumns>

<template>
    <div id="galleryFilter">
        <cardColumns>
            <div :key="index" v-for="(album, index) in albums">
                <b-card no-body>
                    <b-card-header>
                        <h4 class="card-title">
                            <span @click="$emit('updateActive', album)" class="in-place-link">{{ album.title }}</span>
                        </h4>
                    </b-card-header>
                    <b-card-body>
                        <p class="card-text">
                            {{ album.description }}
                        </p>
                    </b-card-body>
                </b-card>
            </div>
        </cardColumns>
    </div>
</template>

<script>
    import cardColumns from '../shared/CardColumns'

    export default {
        name: "galleryListing",
        props: {
            albums: Array
        },
        components: {
            cardColumns
        }
    }
</script>
