<template>
    <div id="registerHeader">
        <h1><strong>Register</strong></h1>
    </div>
</template>

<script>
    export default {
        name: "registerHeader"
    }
</script>