<template>
  <div id="signInBody">
    <card>
      <template v-slot:body>
        <p v-if="link">Our next practice is on {{ getNextPracticeDateFormatted() }}. You should be redirected to the
          sign in form for next practice within a few seconds. If something goes wrong, please email <a
              href="mailto:support@flightclub.dance">support@flightclub.dance</a></p>
        <p v-else>There are currently no practices scheduled. If you think you're seeing this message in error,
          please email <a
              href="mailto:support@flightclub.dance">support@flightclub.dance</a></p>
      </template>
    </card>
  </div>
</template>

<script>
import card from '../shared/Card'

export default {
  name: "signInBody",
  components: {card},
  created() {
    if (this.link) window.location.href = this.link;
  },
  data() {
    return {
      link: "https://forms.gle/Q7ESgwZ4Nk3WZ6Jg7"
    }
  },
  methods: {
    getNextPracticeDateFormatted() {
      return this.getNextPracticeDate().toLocaleString("en-UK", {"dateStyle": "full", "timeStyle": "short"});
    },
    getNextPracticeDate() {
      if (this.nextPracticeIsSunday()) {
        return this.getNextSundayPracticeDate()
      } else {
        return this.getNextWednesdayPracticeDate()
      }
    },
    nextPracticeIsSunday() {
      const now = new Date();
      const dayToday = now.getDay();
      return dayToday === 0 || dayToday > 3
    },
    getNextSundayPracticeDate() {
      return this.getNextPracticeDateOnDay(0)
    },
    getNextWednesdayPracticeDate() {
      return this.getNextPracticeDateOnDay(3)
    },
    getNextPracticeDateOnDay(day) {
      let date = new Date();
      date.setDate(date.getDate() + (day + (7 - date.getDay())) % 7);
      date.setMinutes(30);
      date.setHours(day === 0 ? 18 : 19)
      date.setSeconds(0)
      return date;
    }
  },
}
</script>
