<template>
  <div id="faq">
    <cardColumns>
      <div :key="index" v-for="(faq, index) in faqs">
        <faqCard>
          <span slot="question">{{ faq.question }}</span>
          <span slot="answer">{{ faq.answer }}</span>
        </faqCard>
      </div>
    </cardColumns>
  </div>
</template>

<script>
import cardColumns from '../shared/CardColumns'
import faqCard from './FaqCard'
import {faqsBus} from './faqs'

export default {
  name: "faq",
  data() {
    return {
      faqs: faqsBus.faqs
    }
  },
  components: {
    cardColumns,
    faqCard
  }

}
</script>