<template>
  <div id="registrationSuccessfulBody">
    <card>
      <template v-slot:body>
        <h3 class="text-center">Thank you for registering. You should receive a confirmation email shortly.</h3>
      </template>
    </card>
  </div>
</template>

<script>
import Card from "../shared/Card";

export default {
  name: "registrationSuccessfulBody",
  components: {Card},
}
</script>
