<template>
  <div id="register">
    <server-connected-page>
      <template v-slot:header>
        <register-header/>
      </template>
      <template v-slot:body>
        <register-body/>
      </template>
    </server-connected-page>
  </div>
</template>

<script>
  import registerHeader from './Header.vue'
  import registerBody from './Body.vue'
  import ServerConnectedPage from "../shared/ServerConnectedPage";
  import RegisterHeader from "./Header";
  import RegisterBody from "./Body";

  export default {
    name: 'home',
    data() {
      return {
        registerHeader,
        registerBody,
      }
    },
    components: {
      RegisterBody,
      RegisterHeader,
      ServerConnectedPage
    }
  }
</script>
