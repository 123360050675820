<template>
  <div id="activeGallery">
    <card class="mb-3">
      <template v-slot:body>
        <div class="card-img-top">
          <div class="flex-video widescreen">
            <iframe :src="gallery.link" allowfullscreen/>
            <div class="card-body">
              <h4 class="card-title">
                {{ gallery.title }}
              </h4>
            </div>
          </div>
        </div>
      </template>
    </card>
  </div>
</template>

<script>
import card from '../shared/Card'

export default {
  name: "activeGallery",
  props: {
    gallery: Object
  },
  components: {
    card
  }
}
</script>
