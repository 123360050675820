<template>
  <div id="serverConnectedPage">
    <page>
      <template v-slot:header>
        <slot name="header"></slot>
      </template>
      <template v-slot:subheader>
        <slot name="subheader"></slot>
      </template>
      <template v-slot:body>
        <b-container v-show="!serverConnected">
          <b-row class="row justify-content-center">
            <b-col class="col-12">
              <card bg-variant="info" text-variant="white">
                <template v-slot:body>
                  <h3>
                    Contacting server...
                  </h3>
                  <p>
                    If this message persists, please try again later
                  </p>
                </template>
              </card>
            </b-col>
          </b-row>
        </b-container>
        <slot v-if="serverConnected" name="body"></slot>
      </template>
    </page>
  </div>
</template>

<script>
import {serverStatusBus} from "../../scripts/serverStatus"
import Page from "./Page"
import Card from "./Card"

export default {
  name: 'serverConnectedPage',
  computed: {
    serverConnected() {
      return serverStatusBus.connected
    }
  },
  components: {
    Page,
    Card
  }
}
</script>
