<template>
  <div id="registerForm">
    <b-container>
      <b-row>
        <b-col>
          <card v-show="localError || serverError" bg-variant="danger" text-variant="white">
            <template v-slot:body>
              <h3>{{ localError }}</h3>
              <h3>{{ serverError }}</h3>
            </template>
          </card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <card>
            <template v-slot:body>
              <form @submit.prevent="register()">
                <b-form-group>
                  <b-input-group prepend="Username">
                    <b-input v-model="username" required type="text">{{ username }}</b-input>
                  </b-input-group>
                </b-form-group>
                <b-form-group>
                  <b-input-group prepend="Email">
                    <b-input v-model="email" required type="email">{{ email }}</b-input>
                  </b-input-group>
                </b-form-group>
                <b-form-group>
                  <b-input-group prepend="Password">
                    <b-input placeholder="Minimum 8 characters. Must contain lowercase, uppercase and numbers"
                             v-model="password" required type="password">{{ password }}
                    </b-input>
                  </b-input-group>
                </b-form-group>
                <b-form-group>
                  <b-input-group prepend="Confirm password">
                    <b-input v-model="confirmPassword" required type="password">{{ confirmPassword }}</b-input>
                  </b-input-group>
                </b-form-group>
                <b-form-group>
                  <recaptcha @validate="validateReCaptcha"/>
                </b-form-group>
                <div>
                  <b-button :disabled="!token" type="submit" variant="primary">Register</b-button>
                </div>
              </form>
            </template>
          </card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import card from '../shared/Card'
import {userBus} from "@/scripts/user";
import {validatePassword} from "@/scripts/passwordValidator";
import recaptcha from '../shared/Recaptcha'

export default {
  name: "RegisterForm",
  data: function () {
    return {
      username: "",
      email: "",
      password: "",
      confirmPassword: "",
      localError: "",
      token: undefined
    }
  },
  components: {
    card,
    recaptcha
  },
  methods: {
    validateReCaptcha(response) {
      this.token = response
    },
    async register() {
      try {
        this.verifyFormData()
        userBus.register(this.username, this.email, this.password, this.token)
      } catch (error) {
        this.localError = error
      }
    },
    verifyFormData() {
      const errors = validatePassword(this.password, this.confirmPassword)
      if (errors.length > 0) {
        throw errors.join(". ")
      }
    }
  },
  computed: {
    serverError() {
      return userBus.registrationError ? userBus.registrationError.data.message : null;
    }
  }

}
</script>
