<template>
    <div id="aboutUsHeader">
        <h1><strong>About us</strong></h1>
    </div>
</template>

<script>
    export default {
        name: "aboutUsHeader"
    }
</script>