import Vue from 'vue'
import axios from "axios";

export const requestBus = new Vue({
  methods: {
    request(url, method, payload, callbacks) {
      const headers = {
        'Content-Type': 'application/json',
      }
      // eslint-disable-next-line no-console
      console.info("Sending request: url=" + url)
      const request = {
        url: url,
        method: method,
        headers: headers,
        data: payload
      }
      axios
        .request(request)
        .then(response => {
          callbacks.onSuccess(response.data)
        })
        .catch(e => {
          // eslint-disable-next-line no-console
          console.error("Request failed: " + JSON.stringify({
            request, e
          }))
          callbacks.onFailure(e.response)
        })
    }
  }
});
