import Vue from "vue";

export const galleryBus = new Vue({
  data() {
    return {
      galleries: [
        {
          title: "WMJC Showcase 2023 - Light Em Up",
          date: "2023-03-25",
          link: "https://www.youtube.com/embed/MSX9ijqRX7s",
          description: "Adrian and Amy back in action with Light Em Up, a showcase routine over three years in the making!",
          type: "video"
        },
        {
          title: "WMJC Team Cabaret 2023 - Cops and Robbers",
          date: "2023-03-25",
          link: "https://www.youtube.com/embed/MSX9ijqRX7s",
          description: "Those pesky thieves are after the diamond again! This time at the Tower Ballroom in Blackpool",
          type: "video"
        },
        {
          title: "Welsh Champs 2022 Aerials final",
          date: "2022-10-22",
          link: "https://www.youtube.com/embed/RQscemwVN94",
          description: "Flight Club's finest breaking out their best moves for the Welsh crowd",
          type: "video"
        },
        {
          title: "Welsh Champs 2022 Team Cabaret - Cops and Robbers",
          date: "2022-10-22",
          link: "https://www.youtube.com/embed/MmYlyHrXTw8",
          description: "It's been nearly 4 years since Flight Club's last live team cabaret. Hopefully it was worth the wait! Here we proudly present Cops and Robbers",
          type: "video"
        },
        {
          title: "WMJC Airsteps 2022",
          date: "2022-03-26",
          link: "https://www.youtube.com/embed/jSUcQLYL2Xk",
          description: "Flight Club dominates the floor at the 2022 World Modern Jive Championships airsteps division.",
          type: "video"
        },
        {
          title: "Charleston Abbey",
          date: "2021-07-09",
          link: "https://www.youtube.com/embed/vwsDo2G14Hg",
          description: "Watch our dancers get in the 20s groove with this routine full of charleston, foxtrot and romance.",
          type: "video"
        },
        {
          title: "Christmas 2020: All I Want For Christmas Is You",
          date: "2020-12-20",
          link: "https://www.youtube.com/embed/d7Sc_j-2muc",
          description: "Merry Christmas 2020 from Flight Club. What a year it's been. Even in the closing days it's still got curve balls to throw at us, and this Christmas will be unlike any before. But we're still here, and we're still dancing, and we hope that this video brings a little light to your day.",
          type: "video"
        },
        {
          title: "Flight Club Virtual Cabaret: You Should be Dancing",
          date: "2020-09-23",
          link: "https://www.youtube.com/embed/V5EUl1uXYYE",
          description: "2020's been a tough year not being able to dance together. But that's not stopped us putting together an amazing routine. Choreographed by Amy Welmers Bailey and arranged by Adrian Potter, You Should Be Dancing showcases the team doing what they do best. We might not be able to meet in person but that's not going to stop us dancing.",
          type: "video"
        },
        {
          title: "Flight Club does... Footloose",
          date: "2020-05-17",
          link: "https://www.youtube.com/embed/Ff8Dl6pvpZA",
          description: "A Flight Club recreation to the opening of the 1984 classic, Footloose starring a selection of the Flight Club dancers while we're under lock down during the 2020 Covid-19 outbreak.",
          type: "video"
        },
        {
          title: "Don't Drop Me Now",
          date: "2019-12-22",
          link: "https://www.youtube.com/embed/0ghFHR8jGtc",
          description: "The festive outing of Flight Club's group routine, Don't Drop Me Now at the Jive+ Christmas Party",
          type: "video"
        },
        {
          title: "Oxford Christmas Lights Festival 2019, Caroline and Mo",
          date: "2019-11-16",
          link: "https://www.youtube.com/embed/2uVP12dHgLc",
          description: "Caroline and Mo showing off the new and improved extended version of Runaway Baby at the Oxford Christmas lights festival 2019",
          type: "video"
        },
        {
          title: "Oxford Christmas Lights Festival 2019, Matt and Lillian",
          date: "2019-11-16",
          link: "https://www.youtube.com/embed/DIS1jh17gcE",
          description: "Matt and Lillian showing off their top moves, and grazing the ceiling on stage at the Oxford Christmas lights festival 2019",
          type: "video"
        },
        {
          title: "Light Em Up, Rock Bottoms",
          date: "2019-11-09",
          link: "https://www.youtube.com/embed/rHbPvYeaKfs",
          description: "Adrian and Amy's fantastic new cabaret routine, Light Em Up, performed at Rock Bottoms 2019",
          type: "video"
        },
        {
          title: "Don't Drop Me Now",
          date: "2019-07-24",
          link: "https://www.youtube.com/embed/4N6nnVXxUfk",
          description: "The debut outing of Flight Club's amazing first routine at Jive Champs 2019!",
          type: "video"
        },
        {
          title: "Against Breast Cancer Awards",
          date: "2019-10-24",
          link: "https://www.youtube.com/embed/aaKdBH9vVW4",
          description: "Adam and Christine's fantastic new routine, Skyfall, at the Against Breast Cancer Awards 2019",
          type: "video"
        },
        {
          title: "Runaway Baby, Oxford Echo showcase for The Samaritans and Nightline",
          date: "2019-02-17",
          link: "https://www.youtube.com/embed/2ng9I6sDgzw",
          description: "Mo and Caroline's fantastic debut modern jive demo for the Oxford Echo event in support of Oxford Nightline and The Samaritans.",
          type: "video"
        },
        {
          title: "Shut Up and Dance, Oxford Echo showcase for The Samaritans and Nightline",
          date: "2019-02-17",
          link: "https://www.youtube.com/embed/2p1bhGI8WRk",
          description: "Christine and Adam's fantastic routine, with Adrian subbing in temporarily. A terrific showcase from this husband and wife team.",
          type: "video"
        },
        {
          title: "Jive+ Christmas Party",
          date: "2018-12-23",
          link: "https://www.youtube.com/embed/EBYlWgwaCJE",
          description: "Christine and Adam stealing the show at the Jive+ Christmas Party 2018 with the debut of their very first acrobatic modern jive routine",
          type: "video"
        },
        {
          title: "Against Breast Cancer Awards",
          date: "2018-10-25",
          link: "https://www.youtube.com/embed/rQsTwFCiMeY",
          description: "Amy and Adrian steal the show at the Against Breast Cancer awards 2018 with their hottest moves.",
          type: "video"
        }]
    }
  },
  methods: {
    getAll() {
      return this.galleries
    },
    getActive() {
      return this.galleries[0]
    }
  }
});

