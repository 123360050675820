<template>
    <b-container fluid>
        <b-col class="col-10 offset-1">
            <b-card-group class="card-group">
                <card-with-image :key=index v-for="(card, index) in cards" :title=card.title :link=card.link
                                 :icon=card.icon :icon-alt=card.iconAlt :image=card.image :image-alt=card.imageAlt
                                 :block=card.block />
            </b-card-group>
        </b-col>
    </b-container>
</template>

<script>
    import CardWithImage from "./CardWithImage";

    export default {
        name: 'cardSet',
        components: {
            CardWithImage
        },
        props: {
            cards: Array,
        }
    }
</script>