<template>
    <div id="joinBody">
        <faq/>
    </div>
</template>

<script>
    import faq from './Faq'
    export default {
        name: "joinBody",
        components: {
            faq
        }
    }
</script>
