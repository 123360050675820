<template>
  <div id="verifyResetPasswordForm">
    <b-container>
      <b-row>
        <b-col>
          <card v-show="serverError" bg-variant="danger" text-variant="white">
            <template v-slot:body>
              <h3>{{ serverError }}</h3>
            </template>
          </card>
          <card v-show="localError" bg-variant="danger" text-variant="white">
            <template v-slot:body>
              <h3>{{ localError }}</h3>
            </template>
          </card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <card>
            <template v-slot:body>
              <form @submit.prevent="confirmResetPassword()">
                <b-form-group>
                  <b-input-group prepend="Username">
                    <b-input v-model="username" required type="text">{{ username }}</b-input>
                  </b-input-group>
                </b-form-group>
                <b-form-group>
                  <b-input-group prepend="Password">
                    <b-input placeholder="min 12 characters, must contain lowercase, uppercase, numbers and symbols"
                             v-model="password" required type="password">{{ password }}
                    </b-input>
                  </b-input-group>
                </b-form-group>
                <b-form-group>
                  <b-input-group prepend="Confirm password">
                    <b-input v-model="confirmPassword" required type="password">{{ confirmPassword }}</b-input>
                  </b-input-group>
                </b-form-group>
                <b-form-group>
                  <b-input-group prepend="Verification code">
                    <b-input v-model="verificationCode" required type="text">{{ verificationCode }}</b-input>
                  </b-input-group>
                </b-form-group>
                <b-form-group>
                  <recaptcha @validate="validateReCaptcha"/>
                </b-form-group>
                <div>
                  <b-button :disabled="!token" type="submit" variant="primary">Submit</b-button>
                </div>
              </form>
            </template>
          </card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import card from '../shared/Card'
import {userBus} from "@/scripts/user";
import recaptcha from '../shared/Recaptcha'
import {validatePassword} from "@/scripts/passwordValidator";

export default {
  name: "verifyResetPasswordForm",
  data: function () {
    return {
      username: "",
      password: "",
      confirmPassword: "",
      verificationCode: "",
      token: null,
      localError: null
    }
  },
  components: {
    card,
    recaptcha
  },
  methods: {
    validateReCaptcha(response) {
      this.token = response
    },
    async confirmResetPassword() {
      try {
        this.validatePassword()
        userBus.confirmResetPassword(this.username, this.password, this.verificationCode, this.token)
        this.localError = null
      } catch (error) {
        this.localError = error
      }
    },
    validatePassword() {
      const errors = validatePassword(this.password, this.confirmPassword)
      if (errors.length > 0) {
        throw errors.join(". ")
      }
    }
  },
  computed: {
    serverError() {
      return userBus.resetPasswordConfirmationError ? userBus.resetPasswordConfirmationError.data.message : null;
    }
  }

}
</script>
