<template>
    <div id="homeSubheader" class="jumbotron-subheader">
        The home of acrobatic partner dance in Oxford
    </div>
</template>

<script>
    export default {
        name: "homeSubheader"
    }
</script>
