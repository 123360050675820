<template>
  <div id="gallery">
    <page>
      <template v-slot:header>
        <gallery-header/>
      </template>
      <template v-slot:body>
        <gallery-body/>
      </template>
    </page>
  </div>
</template>

<script>

  import Page from "../shared/Page";
  import GalleryHeader from "./Header";
  import GalleryBody from "./Body";

  export default {
    name: 'galleryHome',
    components: {
      GalleryBody,
      GalleryHeader,
      Page
    }
  }
</script>
