<template>
  <div id="join">
    <page>
      <template v-slot:header>
        <join-header/>
      </template>
      <template v-slot:body>
        <join-body/>
      </template>
    </page>
  </div>
</template>

<script>

  import Page from "../shared/Page";
  import JoinHeader from "./Header";
  import JoinBody from "./Body";

  export default {
    name: 'join',
    components: {
      JoinBody,
      JoinHeader,
      Page
    }
  }
</script>
