<template>
  <div id="verifyResetPassword">
    <page>
      <template v-slot:header>
        <verify-reset-password-header/>
      </template>
      <template v-slot:body>
        <verify-reset-password-body/>
      </template>
    </page>
  </div>
</template>

<script>
  import Page from '../shared/Page.vue'
  import VerifyResetPasswordHeader from "./Header";
  import VerifyResetPasswordBody from "./Body";

  export default {
    name: 'home',
    components: {
      VerifyResetPasswordBody,
      VerifyResetPasswordHeader,
      Page
    }
  }
</script>
