<template>
  <div id="acroHubMoveBody">
    <b-container>
      <b-row>
        <b-col cols="12">
          <b-card>
            <div class="card-img-top">
              <div class="flex-video widescreen">
                <iframe :src="link" allowfullscreen/>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  export default {
    name: "acroHubMoveBody",
    props: {
      link: String
    }
  }
</script>
