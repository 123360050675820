<template>
  <div id="signIn">
    <page>
      <template v-slot:header>
        <sign-in-header/>
      </template>
      <template v-slot:body>
        <sign-in-body/>
      </template>
    </page>
  </div>
</template>

<script>

import Page from "../shared/Page";
import SignInHeader from "@/components/sign-in/Header";
import SignInBody from "@/components/sign-in/Body";

export default {
  name: 'join',
  components: {
    SignInBody,
    SignInHeader,
    Page
  }
}
</script>
