import Vue from 'vue'
import {urlBuilder} from "./urlBuilder";
import {requestBus} from "./request"

export const acroHubBus = new Vue({
    data: function () {
        return {
            moves: []
        }
    },
    methods: {
        getUrl(accessToken, idToken, videoKey, onSuccess) {
            const payload = {
                'accessToken': accessToken,
                'idToken': idToken,
                'key': videoKey
            }
            requestBus.request(
                urlBuilder.build("trainingHub/acrobatics/url"),
                "POST",
                payload,
                {
                    onSuccess: onSuccess, onFailure: function (error) {
                        // eslint-disable-next-line no-console
                        console.error("Unexpected localError getting URL from AcroHub: " + error.response)
                    }
                }
            )
        },
        getMoves(accessToken, idToken) {
            const payload = {
                'accessToken': accessToken,
                'idToken': idToken
            }
            requestBus.request(
                urlBuilder.build("trainingHub/acrobatics/index"),
                "POST",
                payload,
                {
                    onSuccess: function (moves) {
                        acroHubBus.moves = moves
                    },
                    onFailure: function (error) {
                        // eslint-disable-next-line no-console
                        console.error("Unexpected localError getting moves from AcroHub: " + JSON.stringify(error))
                    }
                }
            )
        }
    }
});
