<template>
    <div id="aboutUsHeader">
        <h1><strong>Profile</strong></h1>
    </div>
</template>

<script>
    export default {
        name: "profileHeader"
    }
</script>
