<template>
  <div id="profile">
    <page>
      <template v-slot:header>
        <profile-header/>
      </template>
      <template v-slot:body>
        <profile-body/>
      </template>
    </page>
  </div>
</template>

<script>
  import Page from '../shared/Page.vue'
  import ProfileHeader from "./Header";
  import ProfileBody from "./Body";

  export default {
    name: 'profile',
    components: {
      ProfileBody,
      ProfileHeader,
      Page
    }
  }
</script>
