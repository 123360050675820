<template>
    <div id="homeVideo">
        <div class="flex-video widescreen">
            <iframe src="https://www.youtube.com/embed/MSX9ijqRX7s"/>
        </div>
    </div>
</template>

<script>
    export default {
        name: "homeVideo"
    }
</script>
