<template>
    <div id="verifyResetPasswordHeader">
        <h1><strong>Verify Password Reset</strong></h1>
    </div>
</template>

<script>
    export default {
        name: "verifyResetPasswordHeader"
    }
</script>