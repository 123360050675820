<template>
  <VueRecaptcha :sitekey="this.siteKey" :loadRecaptchaScript="true" @verify="validate"/>
</template>
<script>
  import VueRecaptcha from 'vue-recaptcha'

  export default {
    components: {VueRecaptcha},
    data () {
      return {
        siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
      }
    },
    methods: {
      validate (response) {
        this.$emit('validate', response);
      }
    }
  }
</script>
