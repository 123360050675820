import Vue from 'vue'
import {requestBus} from "./request"
import {urlBuilder} from "./urlBuilder";

export const serverStatusBus = new Vue({
    data() {
        return {
            interval: null,
            connected: false
        }
    },
    methods: {
        getStatus() {
            requestBus.request(
                urlBuilder.build("version"),
                "GET",
                null,
                {
                    onSuccess: function () {
                        serverStatusBus.connected = true
                    }, onFailure: function () {
                        serverStatusBus.connected = false
                    }
                }
            )
        }
    },
    created() {
        this.getStatus()
        this.interval = setInterval(() => this.getStatus(), 10000);
    }
});
