<template>
    <div id="loginBody">
        <loginForm/>
    </div>
</template>

<script>
    import loginForm from './LoginForm'
    export default {
        name: "loginBody",
        components: {
            loginForm
        }
    }
</script>
