<template>
  <div id="page">
    <div>
      <b-jumbotron>
        <b-container>
          <b-row class="row justify-content-center">
            <b-col class="col-auto">
              <h1 class="card-text text-center">
                <slot name="header"></slot>
              </h1>
            </b-col>
          </b-row>
          <b-row class="row justify-content-center">
            <b-col class="col-auto">
              <h2 class="card-text text-center">
                <slot name="subheader"></slot>
              </h2>
            </b-col>
          </b-row>
        </b-container>
      </b-jumbotron>

      <slot name="body"></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'page',
  }
</script>
