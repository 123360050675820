<template>
    <div id="app">
        <navbar/>
        <router-view/>
        <foot/>
    </div>
</template>

<script>
    import foot from './components/shared/Foot'
    import navbar from './components/shared/Navbar'
    export default {
        name: 'app',
        components: {
            foot,
            navbar
        }
    }
</script>