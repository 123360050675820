import home from '../components/home/Page'
import aboutUs from '../components/about/Page'
import acroHub from '../components/acroHub/Page'
import acroHubMove from '../components/acroHubMove/Page'
import classRecord from '../components/classRecord/Page'
import profile from '../components/profile/Page'
import gallery from '../components/gallery/Page'
import join from '../components/join/Page'
import login from '../components/login/Page'
import logout from '../components/logout/Page'
import register from '../components/register/Page'
import registrationSuccessful from '../components/registration-successful/Page'
import requestResetPassword from '../components/request-reset-password/Page'
import signIn from '../components/sign-in/Page'
import verifyResetPassword from '../components/verify-reset-password/Page'

export const routes = [
  {path: '/', component: home},
  {path: '/about', component: aboutUs},
  {path: '/trainingHub/acrobatics', component: acroHub},
  {path: '/trainingHub/acrobatics/:move', component: acroHubMove},
  {path: '/trainingHub/classRecord', component: classRecord},
  {path: '/gallery', component: gallery},
  {path: '/join', component: join},
  {path: '/login', component: login},
  {path: '/logout', component: logout},
  {path: '/profile', component: profile},
  {path: '/register', component: register},
  {path: '/registration-successful', component: registrationSuccessful},
  {path: '/request-reset-password', component: requestResetPassword},
  {path: '/sign-in', component: signIn},
  {path: '/verify-reset-password', component: verifyResetPassword},
  {path: '*', redirect: '/'}
];
