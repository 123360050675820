<template>
  <div id="homeBody">
    <card-set :cards=links />
    <card>
      <template v-slot:body>
        <homeVideo/>
      </template>
    </card>
  </div>
</template>

<script>
import cardSet from '../shared/CardImageSet.vue'
import homeVideo from './HomeVideo.vue'
import card from '../shared/Card'
import {links} from './links.js'

export default {
  name: "homeBody",
  data() {
    return {
      links: links
    }
  },
  components: {
    card,
    cardSet,
    homeVideo
  }
}
</script>
