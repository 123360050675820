<template>
  <div id="acroHubBody">
    <b-container fluid class="mb-3">
      <b-row>
        <b-col cols="12">
          <card v-show="!connected" bg-variant="info" text-variant="white">
            <template v-slot:body>
              <h3>
                Contacting server...
              </h3>
              <p>
                If this message persists, please try again later
              </p>
            </template>
          </card>
        </b-col>
      </b-row>
      <b-row class="justify-content-center">
        <b-col cols="12">
          <card v-show="connected">
            <template v-slot:body>
              <b-form>
                <b-form-group
                    label-cols-lg="2"
                    label="Difficulty"
                    label-size="lg"
                    label-for="difficulty-selector">
                  <b-form-select
                      id="difficulty-selector"
                      v-model="difficultyFilter"
                      :options="difficultyOptions">
                  </b-form-select>
                </b-form-group>
                <b-form-group
                    label-cols-lg="2"
                    label="Sort by"
                    label-size="lg"
                    label-for="sort-selector">
                  <b-form-select
                      id="sort-selector"
                      v-model="sortType"
                      :options="sortOptions">
                  </b-form-select>
                </b-form-group>
                <b-form-group
                    label-cols-lg="2"
                    label="Search"
                    label-size="lg"
                    label-for="text-selector">
                  <b-form-input id="text-selector" v-model="textFilter" type="text"/>
                </b-form-group>
              </b-form>
            </template>
          </card>
        </b-col>
      </b-row>
      <b-row class="justify-content-center">
        <b-col cols="12">
          <card-columns>
            <b-card :key=index v-for="(move, index) in moves" no-body>
              <b-card-header>
                <h4>
                  <router-link :to="move.link">{{ move.name }}</router-link>
                </h4>
              </b-card-header>
              <b-card-body>
                <p class="card-text">
                  <i>Difficulty: </i> {{ difficultyClass(move) }}
                </p>
                <p class="card-text">
                  <i>Last taught: </i> {{ lastTaughtString(move) }}
                </p>
              </b-card-body>
            </b-card>
          </card-columns>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import card from '../shared/Card'
import CardColumns from "../shared/CardColumns";
import {acroHubBus} from "@/scripts/acroHub";

export default {
  name: 'acroHubBody',
  data() {
    return {
      difficultyFilter: 'All',
      difficultyOptions: ["All", "Beginner", "Novice", "Intermediate", "Advanced"],
      sortType: 'Name',
      sortOptions: ["Name", "Date"],
      textFilter: ''
    }
  },
  methods: {
    connected: function () {
      return this.allMoves !== null && this.allMoves.length > 0;
    },
    filterText: function (move) {
      return !this.textFilter || move.name.toLowerCase().includes(this.textFilter.toLowerCase())
    },
    filterTaught: function (move) {
      return this.lastTaught(move)
    },
    filterDifficulty: function (move) {
      return this.difficultyFilter === 'All' || this.difficultyFilter === this.difficultyClass(move)
    },
    filterVideo: function (move) {
      return move["videos"] === undefined ? null : move["videos"]["demo"]
    },
    sortByChosenField: function (a, b) {
      return this.sortType === 'Name' ? this.sortByName(a, b) : this.sortByDate(a, b)
    },
    sortByDate: function (a, b) {
      const dateA = this.lastTaught(a);
      const dateB = this.lastTaught(b);
      if (dateA && dateB) {
        return Date.parse(dateB) - Date.parse(dateA)
      } else if (dateB) {
        return 1
      } else if (dateA) {
        return -1
      } else {
        return 0
      }
    },
    sortByName: function (a, b) {
      return a.name < b.name ? -1 : 1;
    },
    difficultyClass: function (move) {
      const difficulty = move["difficulty"];
      if (difficulty <= 2) return 'Beginner';
      else if (difficulty <= 4) return 'Novice';
      else if (difficulty <= 5) return 'Intermediate';
      else if (difficulty <= 6) return 'Advanced';
      else if (difficulty <= 10) return 'Expert';
      else return 'Unclassified';
    },
    lastTaught: function (move) {
      return move["datesTaught"] ? new Date(move["datesTaught"][0]) : null
    },
    lastTaughtString: function (move) {
      const lastTaught = this.lastTaught(move);
      return lastTaught ? lastTaught.getUTCDate() + "/" + (lastTaught.getMonth() + 1) + "/" + lastTaught.getUTCFullYear() : 'No record'
    },
    moveWithLink: function (key) {
      let move = this.allMoves[key];
      move["link"] = "/trainingHub/acrobatics/" + key;
      return move;
    }
  },
  components: {
    CardColumns,
    card
  },
  computed: {
    allMoves() {
      return acroHubBus.moves
    },
    moves() {
      return Object.keys(this.allMoves)
          .map(this.moveWithLink)
          .filter(this.filterText)
          .filter(this.filterDifficulty)
          .filter(this.filterTaught)
          .filter(this.filterVideo)
          .sort(this.sortByChosenField);
    }
  }
}
</script>
