import Vue from 'vue'
import {router} from "@/main";
import {acroHubBus} from "./acroHub";
import {userBus} from "./user";

export const eventBus = new Vue({
    methods: {
        loggedIn() {
            acroHubBus.getMoves(userBus.accessToken, userBus.idToken)
            router.push('/')
        },
        registered() {
            router.push('/registration-successful')
        },
        passwordResetRequested() {
            router.push('/verify-reset-password')
        },
        passwordResetConfirmed() {
            router.push('/login')
        }
    }
});
