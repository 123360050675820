<template>
    <div id="verifyResetPasswordBody">
        <verifyResetPasswordForm/>
    </div>
</template>

<script>
    import verifyResetPasswordForm from './VerifyResetPasswordForm'

    export default {
        name: "verifyResetPasswordBody",
        components: {
            verifyResetPasswordForm
        }
    }
</script>
