<template>
    <div id="resetPasswordBody">
        <resetPasswordForm/>
    </div>
</template>

<script>
    import resetPasswordForm from './ResetPasswordForm'

    export default {
        name: "resetPasswordBody",
        components: {
            resetPasswordForm
        }
    }
</script>
