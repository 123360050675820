<template>
  <div id="homeHeader">
    <b-img :src="require('../../assets/images/flight_club_name_and_wings_logo.png')" fluid class="jumbotron-image"
           alt="Flight Club with wings on each side"/>
  </div>
</template>

<script>
export default {
  name: "homeHeader"
}
</script>