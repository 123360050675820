<template>
  <div id="logout">
    <page>
      <template v-slot:header>
        <logout-header/>
      </template>
      <template v-slot:body>
        <logout-body/>
      </template>
    </page>
  </div>
</template>

<script>
  import Page from "../shared/Page";
  import LogoutHeader from "./Header";
  import LogoutBody from "./Body";
  export default {
    name: 'logout',
    components: {
      LogoutBody,
      LogoutHeader,
      Page,
    }
  }
</script>
