export const links = [
    {
        block: "Find out more about what we do",
        title: "About us",
        link: "/about",
        image: "adam_christine_demo.jpg",
        imageAlt: "Adam and Christine mid-demo at the Jive+ Christmas party 2018",
        icon: "information",
        iconAlt: "Information icon",
    },
    {
        block: "See the team's latest photos and videos",
        title: "Gallery",
        link: "/gallery",
        image: "gallery.jpg",
        imageAlt: "A triple of acro on Broad Street, Oxford",
        icon: "pictures",
        iconAlt: "Picture icon",
    },
    {
        block: "Interested in giving it a go? Here's everything you need to know",
        title: "Join",
        link: "/join",
        image: "class.jpg",
        imageAlt: "Dancers at one of Flight Club's classes doing a basic shoulder sit",
        icon: "team",
        iconAlt: "Team icon",
    },
];
