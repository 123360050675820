<template>
    <div class="cardColumnsBody">
        <b-container fluid>
            <b-col class="col-10 offset-1">
                <div class="card-columns">
                    <slot/>
                </div>
            </b-col>
        </b-container>
    </div>
</template>

<script>
    export default {
        name: 'cardColumns',
    }
</script>