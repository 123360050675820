export function validatePassword(password, confirmationPassword) {
  let errors = []
  if (!passwordExceedsMinimumLength(password)) {
    errors.push("Password must be at least 8 characters long")
  }
  if (!passwordContainsLowercaseCharacter(password)) {
    errors.push("Password must contain a lowercase character")
  }
  if (!passwordContainsUppercaseCharacter(password)) {
    errors.push("Password must contain a uppercase character")
  }
  if (!passwordContainsNumber(password)) {
    errors.push("Password must contain a number")
  }
  if (!passwordAndConfirmationMatch(password, confirmationPassword)) {
    errors.push("Password and confirmation do not match")
  }
  return errors
}

function passwordAndConfirmationMatch(password, confirmationPassword) {
  return password === confirmationPassword;
}

function passwordExceedsMinimumLength(password) {
  return password.length >= 8
}

function passwordContainsLowercaseCharacter(password) {
  return /[a-z]/.test(password)
}

function passwordContainsUppercaseCharacter(password) {
  return /[A-Z]/.test(password)
}

function passwordContainsNumber(password) {
  return /[0-9]/.test(password)
}
