<template>
  <div id="galleryFilter">
    <card class="mb-3">
      <template v-slot:body>
        <form>
          <div class="form-row">
            <div class="form-group col-3">
              <label for="yearSelector">
                <h4>Year</h4>
              </label>
              <select class="form-control" id="yearSelector" v-model="filter.year">
                <option>All</option>
                <option>2023</option>
                <option>2022</option>
                <option>2021</option>
                <option>2020</option>
                <option>2019</option>
                <option>2018</option>
              </select>
            </div>
            <div class="form-group col-3">
              <label for="typeSelector">
                <h4>Type</h4>
              </label>
              <select class="form-control" id="typeSelector" v-model="filter.type">
                <option>All</option>
                <option>Pictures</option>
                <option>Videos</option>
              </select>
            </div>
            <div class="form-group col-6">
              <label for="textSelector">
                <h4>Search</h4>
              </label>
              <input type="text" class="form-control" id="textSelector" v-model="filter.text"/>
            </div>
          </div>
        </form>
      </template>
    </card>
  </div>
</template>

<script>
import card from '../shared/Card'

export default {
  name: "galleryFilter",
  props: {
    filter: Object
  },
  components: {
    card
  }
}
</script>
