import Vue from "vue";

export const faqsBus = new Vue({
  data() {
    return {
      faqs: [
        {
          question: "Who?",
          answer: "It doesn't matter whether you're a complete beginner, a novice or a pro. Whoever you are, we'd love for you to come along. It's an adult class, so you do need to be over 18, but that's about it. If there's a maximum age, we haven't found it yet.",
        },
        {
          question: "Do I need to let you know I'm coming",
          answer: "Yes please! So that we can continue to provide the best possible classes, and so that we've got up to date disclaimers and emergency contact information, we ask everyone to sign in for each session. To sign in, click 'Sign in' on the navigation bar and it will redirect you to the sign in form",
        },
        {
          question: "When?",
          answer: "We have two sessions. Our main teaching session is 7:30-9:30pm on Wednesdays. If you already come on Wednesdays and just want space to practice, we run an additional non-taught session on Sundays 6:30-8:30pm",
        },
        {
          question: "Where?",
          answer: "St Mary & St John Primary School, Oxford. If traveling by car, please navigate to Bedford Street using postcode OX4 1SU or you'll be on the wrong side of the school to access the car park.",
        },
        {
          question: "What will I be doing?",
          answer: "Wednesday sessions are split into 2 parts: teaching and open practice. We'll start with a quick warm up, followed by around 45 minutes of teaching where we'll cover a selection of related moves of varying difficulty. The remaining time is open practice where couples can practice the moves they've learned from this or previous weeks. Our Sunday sessions are purely open practice, though we may take some time out of the session to practice group routines or run competition run throughs depending on the time of year.",
        },
        {
          question: "How much is it?",
          answer: "Your first three sessions are free! After that, we have either monthly or per-class membership options that range from £2-£4 per hour. As a not-for-profit, all of our teaching and organisation is done on a voluntary basis. Most of our costs go on hall hire. It's about the best value dance or fitness class you'll find anywhere!",
        },
        {
          question: "Do I need a partner?",
          answer: "During the taught portion of the class, there are always other people around who are happy to work with multiple partners. During open practice many people chose to work in fixed couples, but there are almost always others around who aren't fixed. We have a WhatsApp group where you can find out week by week who's planning to attend. You're also welcome to just to come for the taught portion of the class if you prefer."
        },
        {
          question: "What should I wear?",
          answer: " Comfortable sports wear, it's an active session. Preferably something well fitted that won't move around too much when lifting or being lifted. Jewellery will have to be removed for safety reasons."
        }
      ]
    }
  },
  methods: {
    getAll() {
      return this.faqs
    }
  }
});
