<template>
  <div id="loginForm">
    <b-container>
      <b-row>
        <b-col>
          <card v-show="error" bg-variant="danger" text-variant="white">
            <template v-slot:body>
              <h3>{{ error }}</h3>
            </template>
          </card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <card>
            <template v-slot:body>
              <b-form @submit.prevent="getTokens()">
                <b-form-group>
                  <b-input-group prepend="Username">
                    <b-input v-model="username" required type="text">{{ username }}</b-input>
                  </b-input-group>
                </b-form-group>
                <b-form-group>
                  <b-input-group prepend="Password">
                    <b-input v-model="password" required autocomplete="on" type="password">{{ password }}</b-input>
                  </b-input-group>
                </b-form-group>
                <b-form-group>
                  <b-form-checkbox v-model="remember" size="lg" switch>Remember me</b-form-checkbox>
                </b-form-group>
                <b-form-group>
                  <recaptcha @validate="validateReCaptcha"/>
                </b-form-group>
                <b-form-group>
                  <div>
                    <b-button :disabled="!token" type="submit" variant="primary">Sign in</b-button>
                  </div>
                </b-form-group>
              </b-form>
              <hr/>
              <div>
                <p>
                  <router-link to="/register">Register</router-link>
                </p>
                <p>
                  <router-link to="/request-reset-password">Reset password</router-link>
                </p>
              </div>
            </template>
          </card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import card from '../shared/Card'
import recaptcha from '../shared/Recaptcha'
import {userBus} from '@/scripts/user'

export default {
  name: "loginForm",
  components: {
    card,
    recaptcha
  },
  data() {
    return {
      username: "",
      password: "",
      remember: false,
      token: undefined,
    }
  },
  methods: {
    validateReCaptcha(response) {
      this.token = response
    },
    getTokens() {
      userBus.getTokens(this.username, this.password, this.remember, this.token)
    }
  },
  computed: {
    error() {
      return userBus.loginError ? userBus.loginError.data.message : null;
    }
  }
}
</script>
