import Vue from 'vue'
import BootstrapVue, {LayoutPlugin} from 'bootstrap-vue'
import VueRouter from 'vue-router'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import App from './App'
import {routes} from './scripts/routes.js'

Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.use(LayoutPlugin)

export const router = new VueRouter({
  routes: routes,
  mode: "history"
});

new Vue({
  render: h => h(App),
  router
}).$mount('#app');
