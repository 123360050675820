<template>
  <div id="login">
    <server-connected-page>
      <template v-slot:header>
        <login-header/>
      </template>
      <template v-slot:body>
        <login-body/>
      </template>
    </server-connected-page>
  </div>
</template>

<script>

  import ServerConnectedPage from "../shared/ServerConnectedPage";
  import LoginHeader from "./Header";
  import LoginBody from "./Body";

  export default {
    name: 'login',
    components: {
      LoginBody,
      LoginHeader,
      ServerConnectedPage
    }
  }
</script>
