<template>
    <div id="logoutHeader">
        <h1><strong>Logout</strong></h1>
    </div>
</template>

<script>
    export default {
        name: "logoutHeader"
    }
</script>
