<template>
    <div id="registerBody">
        <registerForm/>
    </div>
</template>

<script>
    import registerForm from './RegisterForm'

    export default {
        name: "registerBody",
        components: {
            registerForm
        }
    }
</script>
