<template>
    <div id="resetPasswordHeader">
        <h1><strong>Reset Password</strong></h1>
    </div>
</template>

<script>
    export default {
        name: "resetPasswordHeader"
    }
</script>