<template>
  <div id="navbar">
    <b-navbar class="navbar-dark" toggleable="md">
      <b-navbar-brand to="/">
        <b-img :src="require('../../assets/images/logo_basic.png')" class="navbar-logo"
               alt="Small Flight Club logo"/>
        Flight Club
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item to="/about">About us</b-nav-item>
          <b-nav-item to="/join">Join</b-nav-item>
          <b-nav-item to="/gallery">Gallery</b-nav-item>
          <b-nav-item to="/sign-in">Session sign in</b-nav-item>
          <b-nav-item v-show="!profile" to="/login">Team area</b-nav-item>
          <b-nav-item-dropdown
              id="training-hub-dropdown"
              text="Team area"
              toggle-class="nav-link-custom"
              v-show="canViewTeam">
            <b-nav-item to="/trainingHub/acrobatics">Acrobatics</b-nav-item>
            <b-nav-item v-show="canViewCoaches" to="/trainingHub/classRecord">Class record</b-nav-item>
          </b-nav-item-dropdown>
          <b-nav-item v-show="profile" to="/logout">Logout</b-nav-item>

        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import {userBus} from '@/scripts/user'

export default {
  name: "navbar",
  methods: {
    union(data, values) {
      return data.filter(value => values.includes(value))
    },
    containsOneOf(data, values) {
      return this.union(data, values).length > 0
    },
    canView(profile, groups) {
      return profile ? this.containsOneOf(profile.groups, groups) : false
    }
  },
  computed: {
    profile() {
      return userBus.profile
    },
    canViewTeam() {
      return this.canView(userBus.profile, ['flight_club']);
    },
    canViewCoaches() {
      return this.canView(userBus.profile, ['flight_club']);
    }
  }
}
</script>
