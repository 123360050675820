<template>
  <div id="acroHubMove">
    <server-connected-page>
      <template v-slot:header>
        <acro-hub-move-header :title="moveName"/>
      </template>
      <template v-slot:body>
        <acro-hub-move-body :link="moveLink"/>
      </template>
    </server-connected-page>
  </div>
</template>

<script>
  import {userBus} from "../../scripts/user";
  import {router} from "../../main";
  import {acroHubBus} from "../../scripts/acroHub";
  import AcroHubMoveHeader from "./Header";
  import AcroHubMoveBody from "./Body";
  import ServerConnectedPage from "../shared/ServerConnectedPage";

  export default {
    name: 'acroHubMove',
    data() {
      return {
        moveLink: undefined
      }
    },
    methods: {
      setUrl: function (urlPayload) {
        this.moveLink = urlPayload.url
      }
    },
    components: {
      ServerConnectedPage,
      AcroHubMoveBody,
      AcroHubMoveHeader
    },
    computed: {
      move: function () {
        const moveKey = this.$route.params.move
        return acroHubBus.moves[moveKey]
      },
      moveName: function () {
        return this.move ? this.move.name : null;
      }
    },
    mounted() {
      if (!userBus.profile || !this.move || !this.move.videos || !this.move.videos.demo) {
        router.push('/')
      } else {
        acroHubBus.getUrl(userBus.accessToken, userBus.idToken, this.move.videos.demo, this.setUrl)
      }
    }
  }
</script>
