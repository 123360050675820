<template>
    <div id="loginHeader">
        <h1><strong>Login</strong></h1>
    </div>
</template>

<script>
    export default {
        name: "loginHeader"
    }
</script>