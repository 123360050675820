<template>
  <div id="aboutUsBody">
    <card>
      <template v-slot:body>
        <p>User: {{ name }}</p>
        <p>Email: {{ email }}</p>
        <p>Groups: {{ groups }}</p>
      </template>
    </card>
  </div>
</template>

<script>
import card from '../shared/Card'
import {userBus} from '../../scripts/user'
import {router} from '../../main'

export default {
  name: "profileBody",
  components: {
    card
  },
  methods: {
    getOrDefault(property, defaultValue) {
      return userBus.profile ? userBus.profile[property] : defaultValue
    }
  },
  mounted() {
    if (!userBus.profile) router.push('/')
  },
  computed: {
    name() {
      return this.getOrDefault('username', '')
    },
    email() {
      return this.getOrDefault('email', '')
    },
    groups() {
      return this.getOrDefault('groups', []).join(', ')
    },
  }
}
</script>
